// Splide 4

import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
// import { Grid } from '@splidejs/splide-extension-grid';
import { Intersection } from '@splidejs/splide-extension-intersection';

(($) => {

  let GUID = 0;

  function buildSplide(index, el) {
    let $target = $(el);

    if ($.type($target.data('splide-data')) === 'undefined') {

      // Fetch Inline Options

      let options = $target.data('splide-options') || {};

      let synced = $target.data('splide-synced') || false;
      let $synced = $(synced);

      // Setup Data

      GUID++;

      let id = $target.attr('id') || 'splide-' + GUID;

      let data = {
        GUID: GUID,
        id: id,
        selector: '#' + id,
        $el: $target,
        $items: $target.children(),
      };

      let defaults = {
        mediaQuery: 'min',
        flickPower: 100,
        flickMaxPages: 1,
        updateOnMove: 'true',
        // centerInsufficientSlides: true,
        // grabCursor: true,
        // labels: {
        //   previous: 'Previous',
        //   next: 'Next',
        // },
        // onAny: (event, ...args) => {
        //   // Alias swiper events to jquery
        //   if (typeof args[0].el !== 'undefined') {
        //     let data = $(args[0].el).data('swiper-data');
        //     let clean = Array.prototype.slice.call(args, 1);

        //     // if (typeof data !== 'undefined') {
        //     data.$el.trigger(event + '.swiper', clean);
        //     // }
        //   }
        // },
      };

      options = $.extend({}, defaults, options);

      // Restructure DOM

      data.$el.attr('id', id).addClass('splide');

      // If .slide__list container exists, place all children in it. This allows other elements to
      // exist in the splide parent container. Otherwise take all children in the splide parent
      // container and wrap .splide__list and .splide__track around them.

      if (data.$el.find('.splide__list').length > 0) {
        data.$items = data.$el.find('.splide__list').children();
      } else {
        data.$el.wrapInner('<div class="splide__list"></div>').wrapInner('<div class="splide__track"></div>');
      }

      data.$items.addClass('splide__slide');

  //     let html = '';

  //     if ($.type(options.navigation) !== 'undefined' && options.navigation !== false) {
  //       html += '<div class="swiper-navigation">';
  //       html += '<span class="swiper-button-prev swiper-control swiper-previous">' + options.labels.previous + '</span>';
  //       html += '<span class="swiper-button-next swiper-control swiper-next">' + options.labels.next + '</span>';
  //       html += '</div>';

  //       options.navigation = {
  //         nextEl: data.selector + ' .swiper-button-next',
  //         prevEl: data.selector + ' .swiper-button-prev',
  //       };
  //     }

  //     if ($.type(options.pagination) !== 'undefined' && options.pagination !== false) {
  //       html += '<div class="swiper-pagination"></div>';

  //       if ($.type(options.pagination) !== 'object') {
  //         options.pagination = {};
  //       }

  //       options.pagination.el = data.selector + ' .swiper-pagination';
  //       options.pagination.clickable = true;
  //     }

  //     if ($.type(options.scrollbar) !== 'undefined' && options.scrollbar !== false) {
  //       html += '<div class="swiper-scrollbar"></div>';

  //       if ($.type(options.scrollbar) !== 'object') {
  //         options.scrollbar = {};
  //       }

  //       options.scrollbar.el = data.selector + ' .swiper-scrollbar';
  //       options.scrollbar.clickable = true;
  //     }

  //     data.$el.attr('id', data.id).append(html);

      // Store Data

      data.$el.data('splide-data', data);

      // Create Splide Instance

      // data.Splide = new Splide(data.$el.get(0), options).mount();

      data.Splide = new Splide('#' + id, options);

      if ( $synced.length ) {
        $synced.splide();

        data.Splide.sync( $synced.data('splide-data').Splide );
      }

      let extras = {
        Intersection: Intersection,
      };

      if (typeof options.autoScroll !== 'undefined') {
        extras.AutoScroll = AutoScroll;
      }

      // if (typeof options.grid !== 'undefined') {
      //   extras.Grid = Grid;
      // }

      data.Splide.mount(extras);

      // if (options.controller && options.controller.control) {
      //   let $control = $(options.controller.control);
      //   let controlData = $control.data('swiper-data');

      //   if (controlData) {
      //     data.Swiper.controller.control = controlData.Swiper;
      //     controlData.Swiper.controller.control = data.Swiper;
      //   }
      // }
    }

    return this;
  }

  $.fn['splide'] = function() {
    return $(this).each(buildSplide);
  };

// })(jQuery);

// (($) => {

  window.SiteReady(($) => {
    $('.js-splide').splide();
  });

})(jQuery);